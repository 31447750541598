<template>
    <div class="hello">
        <h1>{{ msg }}</h1>
        <p>
            “Blove For You”
            是一种对生活细节的深情体悟与感恩，它教会我们在平凡的日常中发现非凡的美好。清晨的微笑、忙碌中的坚持、茶香的慰藉，这些都是我们对生活的热爱与执着。无论生活中遇到什么风浪，我们都要勇敢面对，因为这些点滴才构成了独特的人生，留下了我们走过的真实痕迹。
        </p>

        <p>
            “Blove For You”
            是一种对生活细节的感恩和珍视，它教会我们在平凡的日常中发现非凡的美好。在每一个早晨的微笑、每一份工作的努力、每一杯清茶的香气中，都有我们对生活的热爱与执着。无论遇到什么风浪，我们都要勇敢面对，留下了痕迹，才算我们走过！
        </p>
        <p>
            "Blove For
            You"，一种鼓励个人积极进取、自我实现的富有创意的表达方式，激发了对美好生活的向往和对自我成长的追求。
        </p>
        <p>
            “Blove For You”
            是一种对生活细节的深情体悟与鼓励，它象征着在日常琐事中发现美好，激励自己勇敢面对人生的每一个瞬间。
        </p>
        <p>
            清晨，站在洗脸盆旁，看着镜子中的自己，嘴角不禁勾起一丝微笑。迎接新的一天，尽管生活繁忙，却依旧充满期待。地铁缓缓驶来，我挤上了车厢，努力腾出一片空间，终于松了口气。工作计划排满了一整天，当我停下来，透过窗外的光景，才意识到一天即将结束。端起桌边的茶杯，淡淡的香气仍在空气中弥散，带来一丝慰藉。
            电梯轰鸣着将我送到楼下，我仰望着灯光璀璨的办公楼，心中不禁握紧了拳头。来到地铁站，排队等候时，我闭上眼睛，突然感受到一丝熟悉的感觉，仿佛这一切曾经经历过。当我睁开眼睛，发现方向有所改变，但沿途的风浪依旧不可避免。正是这些点滴，构成了我的独特人生，留下了真实的痕迹。
        </p>

        <h3>Installed CLI Plugins</h3>
        <ul>
            <li>
                <a
                    href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-babel"
                    target="_blank"
                    rel="noopener"
                    >babel</a
                >
            </li>
            <li>
                <a
                    href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-eslint"
                    target="_blank"
                    rel="noopener"
                    >eslint</a
                >
            </li>
        </ul>
        <h3>Essential Links</h3>
        <ul>
            <li><a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a></li>
            <li><a href="https://forum.vuejs.org" target="_blank" rel="noopener">Forum</a></li>
            <li><a href="https://chat.vuejs.org" target="_blank" rel="noopener">Community Chat</a></li>
            <li><a href="https://twitter.com/vuejs" target="_blank" rel="noopener">Twitter</a></li>
            <li><a href="https://news.vuejs.org" target="_blank" rel="noopener">News</a></li>
        </ul>
        <h3>Ecosystem</h3>
        <ul>
            <li><a href="https://router.vuejs.org" target="_blank" rel="noopener">vue-router</a></li>
            <li><a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a></li>
            <li>
                <a href="https://github.com/vuejs/vue-devtools#vue-devtools" target="_blank" rel="noopener"
                    >vue-devtools</a
                >
            </li>
            <li><a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener">vue-loader</a></li>
            <li><a href="https://github.com/vuejs/awesome-vue" target="_blank" rel="noopener">awesome-vue</a></li>
        </ul>

        <div class="smiley-face">
            <div class="eyes left-eye"></div>
            <div class="eyes right-eye"></div>
            <div class="nose"></div>
            <div class="mouth"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HelloWorld',
    props: {
        msg: String
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

p::first-letter {
    font-size: 2em;
    color: #42b983;
}

p::selection {
    color: #42b983;
}

.smiley-face {
    position: relative;
    width: 180px; /* 调整大小 */
    height: 180px; /* 调整大小 */
    /*background-color: #FFEB3B;*/ /* 黄色背景 */
    border-radius: 50%; /* 圆形 */
    margin: 15px auto; /* 居中 */
    border: 2px solid #42b983;
}

.eyes {
    position: absolute;
    width: 30px; /* 眼睛大小 */
    height: 30px; /* 眼睛大小 */
    background-color: #42b983; /* 眼睛颜色 */
    border-radius: 50%; /* 圆形眼睛 */
    top: 50px; /* 距离顶部 */
}

.left-eye {
    left: 50px; /* 左眼位置 */
}

.right-eye {
    right: 50px; /* 右眼位置 */
}

.nose {
    position: absolute;
    top: 90px; /* 鼻子距离顶部的位置 */
    left: 50%;
    width: 15px;
    height: 15px;
    background-color: #42b983; /* 鼻子的颜色，可以根据需要调整 */
    border-radius: 50% 50% 0 0; /* 半圆形状 */
    transform: translateX(-50%);
}

.mouth {
    position: absolute;
    bottom: 40px; /* 距离底部 */
    left: 50%;
    width: 100px; /* 嘴巴宽度 */
    height: 40px; /* 嘴巴高度 */
    background-color: transparent; /* 透明背景 */
    border-bottom: 10px solid #42b983; /* 下边缘作为嘴巴 */
    border-radius: 0 0 100px 100px; /* 下半圆形状 */
    transform: translateX(-50%); /* 水平居中 */
}
</style>
